<template>
    <main-layout itemMenuActive="41" tituloMenu="Consulta Diario Reconocimiento Facial">
      <div class="container">
        <br />
        <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <div style="display: flex; justify-content: space-between; align-items: center">
                    <p class="title is-4" style="margin-bottom: auto; margin-top: auto">Consulta Diario Reconocimiento Facial</p>
                    <div>
                      <b-button v-on:click="exportExcel" :disabled="dataTable.length === 0">Excel</b-button>
                      <b-button v-on:click="exportCSV" :disabled="dataTable.length === 0">CSV</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-6">
            <div class="card">
              <div class="card-content">
                <span>Transacciones Correctas VS Fallidas</span>
                <div v-if="series.length > 0">
                  <div id="chart">
                    <apexchart
                      type="pie"
                      width="380"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </div>
                <div v-else>
                  <b-message type="is-info"> Sin datos para procesar. </b-message>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dataTable.length > 0">
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <TableCard messageEmpty="Sin datos para procesar."
                            :columns="columnsTable" :info="dataTable"
                           detailKey="counter" :tableConfig="tableConfig"
                           :haveFilter="false"></TableCard>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-3">
                <div class="card-content" style="text-align: center">
                  <b-field grouped>
                    <b-tag type="is-primary" class="control" size="is-medium"
                      >Transacciones Exitosas: {{ total_exitosas }}</b-tag
                    >
                  </b-field>
                </div>
            </div>
            <div class="column is-3">
                <div class="card-content" style="text-align: center">
                  <b-field grouped>
                    <b-tag type="is-warning" class="control" size="is-medium"
                      >Transacciones Fallidas: {{ total_fallidas }}</b-tag
                    >
                  </b-field>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-message type="is-info"> Sin datos para procesar. </b-message>
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </main-layout>
  </template>
  <script>
  import MainLayout from "@/components/Layout/MainLayout";
  import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
  import apexchart from "vue-apexcharts";
  import {COLUMNS_FACE_RECOGNITION_PAGE_TABLE} from "../../assets/config/constants/TableConstants";
  import {formatData} from "../../assets/config/utilities";
  import TableCard from "../../components/cards/TableCard";
  import {saveLog} from "../../services/exportLogs";

  export default {
    name: "ReporteDiarioFaceRecognitionFinan",
    components: { MainLayout, apexchart,TableCard },
    data() {
      return {
        total_exitosas: 0,
        total_fallidas: 0,
        isPaginated: true,
        isPaginationRounded: true,
        perPage: 10,
        isLoading: false,
        dataTable: [],
        counter: 0,
        series: [],
        tableConfig: {
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: false,
        },
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: ["Correctas", "Fallidas"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        columnsTable: COLUMNS_FACE_RECOGNITION_PAGE_TABLE,
        fecha_inicial: new Date(),
        fecha_final: new Date(),
        rango_fecha: [new Date(), new Date()],
        locale: "en-CA",
        today: new Date(),
      };
    },
    computed: {
      ...mapState(["_HOST_SERVE","_PERFIL_MODULO_"]),
    },
    mounted() {
      this.handleSubmit();
      this.$emit("tituloTransaccion", "VU Face Recogn");
      this.nombreTituloMenu("Face Recognition");
    },
    methods: {
      handleSubmit() {

        if(this._PERFIL_MODULO_.facerecognition){
          this.isLoading = true;

          this.dataTable = [];
          this.counter = 0;
          const options = {
            method: "post",
            uri: "finan/Face",
            data: {
              FechaInicial: this.today,
              FechaFinal: this.today,
              tipo: "day",
            },
          };

          this.series.length = 0;
          this._axios(options)
            .then((response) => {
              response.data.datos.forEach((item) => {
                this.counter++;
                item.counter = this.counter;
                this.dataTable.push(item);
              });
              this.dataTable = formatData(this.dataTable);
              this.total_exitosas = response.data.cant_exitosas;
              this.total_fallidas = response.data.cant_fallidas;
              if (
                    response.data.cant_exitosas > 0 ||
                    response.data.cant_fallidas > 0
                ) {
                  this.series.push(response.data.cant_exitosas);
                  this.series.push(response.data.cant_fallidas);
                }
            })
            .catch((error) => {
              this.isLoadingTable = false;
              console.log(error);
            })
            .finally(() => this.cancelarLoading());
        }else{
          this.$router.push('login')
        }
      },
      exportExcel: function () {
      this.exportFile("exportExcelFaceFinan");
          this.saveExportEvent("excel")
      },
      exportCSV() {
        this.exportFile("exportCSVFaceFinan");
          this.saveExportEvent("csv")
      },
      exportFile(type) {
        let fechaInicial = this.convertFecha(new Date());
        let fechaFinal = this.convertFecha(new Date());
        let routeData = `${this._HOST_SERVE}/finan/${type}/${fechaInicial}/${fechaFinal}`;
        window.open(routeData, "_blank");
      },
      convertFecha: function (fecha) {
        console.log(fecha);
        let date = new Date(fecha).toISOString().slice(0, 10);
        return date;
      },
      alertCustomError(msj, titulo = "Error", tipo = "is-danger") {
        this.$buefy.dialog.alert({
          title: titulo,
          message: msj,
          type: tipo,
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      cancelarLoading() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      },

        saveExportEvent(type) {
            saveLog({
                "user": this.getUsuario(),
                "sectionId": 2,
                "reportId": 4,
                "format": type,
            });
        },
      ...mapMutations(["nombreTituloMenu"]),
      ...mapActions(["_axios"]),
        ...mapGetters(["getUsuario"])
    },
  };
  </script>
  <style>
  :root {
    --primary: #00522a;
  }

  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: #00522a;
    color: #fff;
  }

  .pagination-link.is-current {
    background-color: #00522a;
    border-color: #00522a;
    color: #fff;
  }

  .tag:not(body).is-primary {
    background-color: #00522a;
    color: #fff;
  }
  .has-text-primary {
    color: #00522a !important;
  }
  .styled-select select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: #00522a;
  }
  .select select:focus,
  .taginput .taginput-container.is-focusable:focus,
  .textarea:focus,
  .input:focus,
  .select select.is-focused,
  .taginput .is-focused.taginput-container.is-focusable,
  .is-focused.textarea,
  .is-focused.input,
  .select select:active,
  .taginput .taginput-container.is-focusable:active,
  .textarea:active,
  .input:active,
  .select select.is-active,
  .taginput .is-active.taginput-container.is-focusable,
  .is-active.textarea,
  .is-active.input {
    border-color: #00522a;
  }
  .datepicker .dropdown .input[readonly]:focus,
  .datepicker .dropdown .input[readonly].is-focused,
  .datepicker .dropdown .input[readonly]:active,
  .datepicker .dropdown .input[readonly].is-active,
  .datepicker .dropdown-trigger .input[readonly]:focus,
  .datepicker .dropdown-trigger .input[readonly].is-focused,
  .datepicker .dropdown-trigger .input[readonly]:active,
  .datepicker .dropdown-trigger .input[readonly].is-active {
    box-shadow: none;
  }

  .pagination-previous:focus,
  .pagination-next:focus,
  .pagination-link:focus {
    border-color: #00522a;
  }

  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
    border: solid 1px rgba(0, 82, 42, 1);
  }
  </style>
  